import React, { useState, useEffect, useRef } from "react";
import "./Donation.css";
import shape from "../../../media/shape.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { faKitMedical } from "@fortawesome/free-solid-svg-icons";

const Donation = () => {
  const iconSize = "2x";
  const donationRef = useRef(null);
  const [countStarted, setCountStarted] = useState(false);

  const handleScroll = () => {
    if (donationRef.current && !countStarted) {
      const top = donationRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (top < windowHeight) {
        setCountStarted(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [volunteers, setVolunteers] = useState(0);
  const [donations, setDonations] = useState(0);
  const [fundsRaised, setFundsRaised] = useState(0);
  const [rescues, setRescues] = useState(0);

  useEffect(() => {
    if (countStarted) {
      const interval1 = setInterval(() => {
        setVolunteers((prevVolunteers) =>
          prevVolunteers >= 100 ? 100 : prevVolunteers + 1
        );
      }, 20);
      const interval2 = setInterval(() => {
        setDonations((prevDonations) =>
          prevDonations >= 1000 ? 1000 : prevDonations + 10
        );
      }, 20);
      const interval3 = setInterval(() => {
        setFundsRaised((prevFundsRaised) =>
          prevFundsRaised >= 15000 ? 15000 : prevFundsRaised + 100
        );
      }, 20);
      const interval4 = setInterval(() => {
        setRescues((prevRescues) => (prevRescues >= 45 ? 45 : prevRescues + 1));
      }, 20);

      return () => {
        clearInterval(interval1);
        clearInterval(interval2);
        clearInterval(interval3);
        clearInterval(interval4);
      };
    }
  }, [countStarted]);

  useEffect(() => {
    if (countStarted) {
      const timeout = setTimeout(() => {
        setVolunteers(100);
        setDonations(1000);
        setFundsRaised(45000);
        setRescues(45);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [countStarted]);

  return (
    <div className="main-donation-div" ref={donationRef}>
      <div className="container">
        <div className="row">
          <div className="col-md-3 text-center">
            <div className="donation-contents">
              <span>{volunteers}+</span>
              <div className="icon-div">
                <FontAwesomeIcon icon={faHandshake} size={iconSize} />
              </div>
              <h2 className="name-donation"> Volunteers</h2>
            </div>
          </div>
          <div className="col-md-3 text-center">
            <div className="donation-contents">
              <span>{donations}+</span>
              <div className="icon-div">
                <FontAwesomeIcon icon={faHandHoldingDollar} size={iconSize} />
              </div>
              <h2 className="name-donation"> Donations</h2>
            </div>
          </div>
          <div className="col-md-3 text-center">
            <div className="donation-contents">
              <span>{fundsRaised}$ +</span>
              <div className="icon-div">
                <FontAwesomeIcon icon={faMoneyBill} size={iconSize} />
              </div>
              <h2 className="name-donation"> Fund Raised</h2>
            </div>
          </div>
          <div className="col-md-3 text-center">
            <div className="donation-contents">
              <span>{rescues}+</span>
              <div className="icon-div">
                <FontAwesomeIcon icon={faKitMedical} size={iconSize} />
              </div>
              <h2 className="name-donation"> Rescues</h2>
            </div>
          </div>
        </div>
        <img className="shape-img" src={shape} />
      </div>
    </div>
  );
};

export default Donation;
