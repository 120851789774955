import React from "react";
import Navbar from "../Homepage/components/Navbar";
import Involvedheader from "../Involved/components/Involvedheader";
import Topheading from "./components/Topheading";

const Involved = () => {
  return (
    <div>
      <Involvedheader />
      <Topheading />
    </div>
  );
};

export default Involved;
