import React from "react";
import "./Youngheader.css";
const Youngheader = () => {
  return (
    <div className="Youngheader-main text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Youngcontents">
              <h2 className="Young-title">
                Blood and Life Donation Counselling
              </h2>
              <button className="Young-sub">
                <span>Our Featured Programs</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Youngheader;
