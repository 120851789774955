import React from "react";
import "./Progtitle.css";
const Progtitle = () => {
  return (
    <div className="pasttheader-main-div">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="pastt-header text-center">
              <span className="pastt-small-text">
                Uniting Hearts for a Cause Worth Supporting
              </span>
              <h2 className="pastt-title">Join Our Upcoming Events</h2>
              <img
                className="design1"
                src="https://htmldemo.net/givest/givest/assets/img/shape/3.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progtitle;
