import React from "react";
import Aboutnew from "./components/Aboutnew.js";
import Donation from "./components/Donation.js";
import Navbar from "./components/Navbar";
import ProgHighlight from "./components/ProgHighlight";
import Testimonial1 from "./components/Testimonial1.js";
import Herosection from "./components/Herosection";
import Footer from "./components/Footer.js";
import Upcommingprog from "./components/Upcommingprog.js";
import Ctasection from "./components/Ctasection.js";
import Past from "./components/Past.js";
import Progtitle from "./components/Progtitle.js";
import { useEffect } from "react";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, []);
  return (
    <div>
      <Herosection />

      <Aboutnew />
      <Donation />
      <Progtitle />
      <Past />
      {/* <Ctasection /> */}
    </div>
  );
};

export default Homepage;
