import React from "react";
import "./Contactinfo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import personimg from "../../../media/personimg.jpg";
import {
  faEnvelope,
  faLocation,
  faLocationArrow,
  faLocationPin,
  faLocationPinLock,
  faMailBulk,
  faMailForward,
  faMapLocation,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Contactinfo = () => {
  const iconSize = "2x";
  return (
    <div className="contact-info-div">
      {/* <div className="container">
        <div className="row">
          <div
            className="col-md-12 text-center
          "
          >
            <img src={personimg} />{" "}
            <h3 className="person-name">Mr. Fanindra Panta</h3>
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="contact-infos text-center">
              <FontAwesomeIcon
                icon={faMapLocation}
                size={iconSize}
                className="icon-contact"
              />
              <h2 className="contact-title">Address</h2>
              <p className="contact-text">
                Grote Belt 56, 2133 GB Hoofddorp
                <br /> Netherlands
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-infos text-center">
              <FontAwesomeIcon
                icon={faEnvelope}
                size={iconSize}
                className="icon-contact"
              />
              <h2 className="contact-title">Email Us</h2>
              <p className="contact-text">
                info247@nepalifecare.org <br />
              </p>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="contact-infos">
              <FontAwesomeIcon
                icon={faPhone}
                size={iconSize}
                className="icon-contact"
              />
              <h2 className="contact-title">Call Now</h2>
              <p className="contact-text">
                {" "}
                0031 642486868 <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactinfo;
