import React, { useEffect } from "react";
import Header from "./components/header";
import Navbar from "../Homepage/components/Navbar";
import Mission from "./components/Mission";
import Counselteam from "../Cgpage/components/Counselteam";
import Testimonial1 from "../Homepage/components/Testimonial1";
import Footer from "../Homepage/components/Footer";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, []);

  return (
    <div>
      <Header />
      <Mission />
      <Counselteam />
    </div>
  );
};

export default Aboutus;
