import React from "react";
import firstimg from "../../../media/first.jfif";
import "./Aboutnew.css";
import counselimg from "../../../media/sliderimg11.jpeg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import abouttexts from "../../../components/Homepage/components/homepage.json";
const Aboutnew = () => {
  return (
    <div className="aboutnew-main">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-main-div">
              <div className="toppp">
                <div className="hline"></div>
                <h5 className="title-small">About Us</h5>
              </div>

              <h2 className="title-large">
                <span className="span-about">Welcome </span>to NepalLifeCare,
                where compassion meets action
              </h2>
            </div>
          </div>
          <div className="col-xl-7 col-lg-5">
            <div className="aboutcontent-left">
              <img className="img1" src={counselimg} />
              <img className="img2" src={firstimg}></img>
            </div>
          </div>
          <div className="col-xl-5 col-lg-7">
            <div className="aboutcontent-right">
              <div className="aboutright-top">
                <div className="linee"></div>
                <p className="abouttextmain">
                  "Your gateway to impactful change, fostering hope and
                  transforming lives across diverse communities"
                </p>
              </div>
              <p className="abouttextfirst">
                Our foundation's mission is to champion youth mental health,
                blood donation initiatives, and support for the needy. Guided by
                a vision of a world where every individual has access to mental
                wellness resources, lifesaving blood supplies, and essential
                aid, we strive to make a lasting impact.
              </p>
              <p className="abouttextsecond">
                Our values center on compassion, integrity, and community
                collaboration, driving us to uphold the highest standards in all
                our endeavors. Core principles include promoting mental health
                awareness, facilitating blood drives to save lives, and
                delivering aid to those facing hardship. Together, we aim to
                create positive change and build a brighter future for all.
              </p>
              <ul className="listabout">
                <li>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{ color: "#ef5f34", paddingRight: "20px" }}
                  />
                  {abouttexts.aboutpoint1}
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{ color: "#ef5f34", paddingRight: "20px" }}
                  />
                  {abouttexts.aboutpoint2}
                </li>
                <li>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{ color: "#ef5f34", paddingRight: "20px" }}
                  />
                  {abouttexts.aboutpoint3}
                </li>
              </ul>
              <button className="about-buttonn">
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to="/Aboutpage"
                >
                  <span className="butt-span">Learn more</span>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutnew;
