import React from "react";
import "./Mission.css";
import compassion from "../../../media/compassion.png";
import sustainable from "../../../media/sustainable.png";
import support from "../../../media/support.png";
import innovation from "../../../media/innovation.png";
import transparency from "../../../media/transparency.png";
import prestige from "../../../media/prestige.png";
import bgimg1 from "../../../media/bgimg1.jpg";
import bgimg2 from "../../../media/bgimg2.jpg";
const Mission = () => {
  const iconSize = "5x";
  return (
    <div className="main-about-dib">
      <div className="main-mission-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="mission-contents">
                <h1 className="mission-header">Our Missions</h1>

                <p className="mission-text">
                  At NepaliLifeCare, we are on a heartfelt journey to
                  revolutionize the landscape of youth well-being and lifesaving
                  initiatives. Our mission pulsates with the fervent commitment
                  to elevate youth mental health and blood donation to
                  unprecedented heights. With unwavering dedication, we champion
                  the cause of mental wellness among young individuals, igniting
                  hope and resilience in their hearts. Simultaneously, we
                  orchestrate a symphony of compassion and solidarity, rallying
                  communities to embrace the noble act of blood donation, a
                  beacon of life in times of dire need. With boundless passion
                  and unwavering resolve, we stand as guardians of well-being,
                  shaping a future where every heartbeat resonates with
                  vitality, every mind brims with serenity, and every act of
                  kindness saves a precious life. Join us as we paint the canvas
                  of tomorrow with strokes of empathy, courage, and boundless
                  love.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line-container">
        <div className="vertical-line"></div>
      </div>
      <div className="main-vision-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="vision-contents">
                <h1 className="vision-header">Our Visions</h1>

                <p className="vision-text">
                  At NepaliLifeCare, we envision a future where every young
                  person is empowered to prioritize their mental health and
                  well-being, and where blood donation is embraced as a
                  fundamental act of compassion and solidarity. We strive
                  towards a society where mental wellness is destigmatized, and
                  access to mental health resources is readily available to all.
                  Additionally, we envision a community where blood donation is
                  ingrained in the culture, ensuring an ample supply of
                  lifesaving blood for those in need. Through our concerted
                  efforts, we aspire to foster a healthier, more resilient
                  community where every individual can thrive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line-container">
        <div className="vertical-line"></div>
      </div>
      <div className="main-values-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="values-content">
                <h1 className="values-header">Our Values</h1>
                <p className="values-text">
                  Our core values serve as the guiding principles that underpin
                  everything we do at NepaliLifeCare:
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="vision-icon-contents">
                <img className="about-images" src={compassion} />
                <h2 className="vision-icon-name">Compassion</h2>
                <p className="vision-icon-text">
                  We approach our mission with boundless compassion and empathy,
                  recognizing the inherent dignity and worth of every individual
                  we serve.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="vision-icon-contents">
                <img className="about-images" src={sustainable} />
                <h2 className="vision-icon-name">Sustainable</h2>
                <p className="vision-icon-text">
                  Ensuring that our programs and initiatives are environmentally
                  sustainable and have a long-lasting positive impact on the
                  community.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="vision-icon-contents">
                <img className="about-images" src={support} />
                <h2 className="vision-icon-name">Support</h2>
                <p className="vision-icon-text">
                  Working together with partners, volunteers, and the community
                  to achieve common goals and maximize our impact.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="vision-icon-contents">
                <img className="about-images" src={innovation} />
                <h2 className="vision-icon-name">Innovation</h2>
                <p className="vision-icon-text">
                  Embracing creativity and innovation to find new and effective
                  ways to address social challenges and improve the lives of
                  others.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="vision-icon-contents">
                <img className="about-images" src={transparency} />
                <h2 className="vision-icon-name">Transparency</h2>
                <p className="vision-icon-text">
                  Being open and honest about our actions, decisions, and use of
                  resources, and maintaining clear communication with
                  stakeholders.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="vision-icon-contents">
                <img className="about-images" src={prestige} />
                <h2 className="vision-icon-name">Dignity</h2>
                <p className="vision-icon-text">
                  Respecting the inherent dignity and worth of every individual,
                  and treating them with kindness, empathy, and respect.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
