import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import sliderimg1 from "../../../media/usibaku5.jpg";
import sliderimg2 from "../../../media/asim.jpg";
import sliderimg3 from "../../../media/usiba.png";
import textdata from "../components/homepage.json";
import slidermobile1 from "../../../media/usibaku5mobile.jpg";
import slidermobile2 from "../../../media/asimmobile.jpg";
import slidermobile3 from "../../../media/usibamobile.png";
import "./Herosection1.css";
function Herosection() {
  return (
    <Carousel fade controls={true} interval={4000}>
      <Carousel.Item>
        <img className="laptop-img" src={sliderimg1} />
        <img className="mobile-img" src={slidermobile1} />
        <img
          className="purplebg"
          src="https://gaviaspreview.com/wp/oxpitan/wp-content/uploads/2015/12/layer-1.png"
        />
        <div className="hero-content">
          <h3 className="hero-title">Welcome To NepaLifeCare</h3>
          <h2 className="hero-text">
            Inform, Inspire <span className="greeni">and</span> Ignite for Happy
            <span className="greeni"> Life </span>
          </h2>
          <button className="hero-button">
            <Link
              style={{
                fontSize: "17px",
                textDecoration: "none",
              }}
              to="/Aboutpage"
            >
              <span>Read More</span>
            </Link>
          </button>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <img className="laptop-img" src={sliderimg2} />
        <img className="mobile-img" src={slidermobile2} />
        <img
          className="purplebg"
          src="https://gaviaspreview.com/wp/oxpitan/wp-content/uploads/2015/12/layer-1.png"
        />
        <div className="hero-content">
          <h3 className="hero-title">Welcome To NepaLifeCare</h3>
          <h2 className="hero-text">
            Stand up for the deprived, make <br /> them feel better.
          </h2>
          <button className="hero-button">
            <Link
              style={{
                fontSize: "17px",
                textDecoration: "none",
              }}
              to="/Aboutpage"
            >
              <span>Read More</span>
            </Link>
          </button>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <img className="laptop-img" src={sliderimg3} />
        <img className="mobile-img" src={slidermobile3} />
        <img
          className="purplebg"
          src="https://gaviaspreview.com/wp/oxpitan/wp-content/uploads/2015/12/layer-1.png"
        />
        <div className="hero-content">
          <h3 className="hero-title">Welcome To NepaLifeCare</h3>
          <h2 className="hero-text">
            Empower lives, <span className="kolar"> join </span> our cause for a
            lasting happy <span className="kolar"> life. </span>
          </h2>
          <button className="hero-button">
            <Link
              style={{
                fontSize: "17px",
                textDecoration: "none",
              }}
              to="/Aboutpage"
            >
              <span>Read More</span>
            </Link>
          </button>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Herosection;
