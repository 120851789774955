import React from "react";
import Navbar from "../Homepage/components/Navbar";
import Header from "./components/header";
const Startups = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default Startups;
