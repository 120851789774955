import React from "react";
import "./Aboutdon.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import abouttexts from "../../../components/Homepage/components/homepage.json";
import charitytest1 from "../../../media/charitytest1.png";
import youth from "../../../media/youth123.png";
const Aboutnew = () => {
  return (
    <div className="donyouth">
      <div className="aboutdon-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-main-div-don">
                <div className="toppp-don">
                  <div className="hline-don"></div>
                  <h5 className="title-small-don">
                    Blood and Life Donation Counselling Program
                  </h5>
                </div>

                <h2 className="title-large-don">
                  <span className="span-about-don">Saving</span> lives : One
                  Drop At a Time
                </h2>
              </div>
            </div>
            <div className="col-md-5">
              <div className="aboutcontent-left-don">
                <img className="img1-don" src={charitytest1} />
              </div>
            </div>
            <div className="col-md-7">
              <div className="aboutcontent-right-don">
                <div className="aboutright-top-don">
                  <div className="linee-don"></div>
                  <p className="abouttextmain-don">
                    "Welcome to NepalifeCare's Blood and Life Donation page,
                    where compassion meets action. Join us in this noble cause
                  </p>
                </div>
                <p className="abouttextfirst-don">
                  At NepalifeCare, we understand the critical importance of a
                  robust blood donation system. Whether it's providing emergency
                  transfusions for accident victims, supporting patients
                  undergoing complex surgeries, or helping individuals battling
                  life-threatening illnesses, donated blood is a lifeline for
                  those in need. However, despite its significance, blood
                  shortages persist in many regions, including Nepal. By
                  participating in the Blood and Life Donation Counselling
                  Program with NepalifeCare, you become a beacon of hope for
                  those facing medical emergencies. Your generosity not only
                  saves lives but also instills a sense of community and
                  solidarity. Each drop of blood you donate has the potential to
                  restore health, reunite families, and inspire others to follow
                  in your footsteps.
                </p>
                <p className="abouttextsecond-don">
                  Apart from the satisfaction of knowing you've helped save a
                  life, there are also health benefits associated with blood
                  donation. It stimulates the production of new blood cells,
                  reduces the risk of certain diseases, and promotes overall
                  well-being. Moreover, it's a simple and safe procedure, with
                  trained professionals guiding you every step of the way.
                </p>
                <ul className="listabout-don"></ul>
                <button className="about-buttonn-don">
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to="/Aboutpage"
                  >
                    <span className="butt-span-don">
                      "Join us in our mission to make a difference in the lives
                      of those in need. Your generosity has the power to
                      transform despair into hope, illness into recovery, and
                      tragedy into triumph. Together, let's ensure that the gift
                      of life continues to flow freely."
                    </span>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="youthprog">
        <div className="aboutyouth-main">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-main-div-youth">
                  <div className="toppp-youth">
                    <div className="hline-youth"></div>
                    <div className="title-youthh"></div>
                    <h5 className="title-small-youth">
                      Youth Mental Health Wellbeing Program
                    </h5>
                  </div>
                  <div className="title-youth">
                    <h2 className="title-large-youth">
                      <span className="span-about-youth">Nurturing Minds </span>
                      Supporting Young Mental Health
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="aboutcontent-right-youth">
                  <div className="aboutright-top-youth">
                    <div className="linee-youth"></div>
                    <p className="abouttextmain-youth">
                      "Welcome to Nepalifecare's Young Mental Health page, where
                      we recognize the importance of well-being of our youth.
                    </p>
                  </div>
                  <p className="abouttextfirst-youth">
                    At Nepalifecare, we take a holistic approach to supporting
                    young mental health. This involves providing access to
                    resources, such as counseling services, support groups, and
                    educational workshops, that equip young individuals with the
                    skills and knowledge they need to navigate life's ups and
                    downs effectively.One of the biggest barriers to seeking
                    help for mental health issues is the stigma that surrounds
                    it. Through awareness campaigns, community outreach, and
                    destigmatization efforts, we strive to create a culture
                    where seeking help for mental health concerns is viewed as a
                    sign of strength, not weakness.
                  </p>
                  <p className="abouttextsecond-youth">
                    Apart from the satisfaction of knowing you've helped save a
                    life, there are also health benefits associated with blood
                    donation. It stimulates the production of new blood cells,
                    reduces the risk of certain diseases, and promotes overall
                    well-being. Moreover, it's a simple and safe procedure, with
                    trained professionals guiding you every step of the way.
                  </p>
                  <ul className="listabout-youth"></ul>
                  <button className="about-buttonn-youth">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/Aboutpage"
                    >
                      <span className="butt-span-youth">
                        "Join us in our commitment to supporting the mental
                        health and well-being of Nepal's youth. Together, we can
                        break down barriers, challenge stigma, and create a
                        brighter future where every young person feels empowered
                        to prioritize their mental health and pursue their
                        dream"
                      </span>
                    </Link>
                  </button>
                </div>
              </div>
              <div className="col-md-5">
                <div className="aboutcontent-left-youth">
                  <img className="img1-youth" src={youth} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Aboutnew;
