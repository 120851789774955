import React from "react";
import "./Topheading.css";
import heart1 from "../../../media/heart1.png";
const Topheading = () => {
  return (
    <div className="main-involved-div">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="join-titlepart">
              <div className="firstpartt">
                <div className="hline1"></div>
                <h5 className="title-small1">Join Us</h5>
              </div>
              <h2 className="title-join">
                If You are Interested! You can Join Us{" "}
                <span className="title-join-span">As A Volunteer</span>
              </h2>
              <p className="join-us-text">
                At NepalLife Care, we are dedicated to making a positive impact
                in the lives of individuals and communities across Nepal. Our
                volunteering program offers you a unique opportunity to
                contribute your time, skills, and passion towards meaningful
                projects that address various social issues and promote
                sustainable development.
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-part-inv1">
              <input className="enpoot1" placeholder="First Name" />
              <input className="enpoot1" placeholder="Last Name" />
              <input className="enpoot1" placeholder="Email" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-part-inv2">
              <input className="enpoot1" placeholder="Age" />
              <input className="enpoot1" placeholder="Religion" />
              <input className="enpoot1" placeholder="Phone" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-part-inv3">
              <textarea className="enpoot2" placeholder="Message" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="cvgaiz">
              <label htmlFor="cvUpload">Upload Your CV:</label>
              <input
                type="file"
                id="cvUpload"
                name="cvUpload"
                accept=".pdf,.doc,.docx"
              />
              <small className="form-text text-muted">
                Accepted file formats: PDF, DOC, DOCX
              </small>
            </div>
          </div>
          <div className="col-md-12">
            <div className="button-dib">
              <button className="about-buttonnn">Submit Now !</button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="cta-section-2">
              <div className="shape-1">
                <img src={heart1} />
              </div>
              <div className="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topheading;
