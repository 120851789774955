import { React, useEffect } from "react";
import Navbar from "../Homepage/components/Navbar";
import Contactheader from "./components/Contactheader";
import Contactinfo from "./components/Contactinfo";
import Contactform from "./components/Contactform";
import Sociallinks from "./components/Sociallinks";
import Footer from "../Homepage/components/Footer";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, []);
  return (
    <div>
      <Contactheader />
      <Contactinfo />
      <Sociallinks />
      <Contactform />
    </div>
  );
};

export default Contact;
