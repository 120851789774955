import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHandshake,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
const Footer = () => {
  const iconSize = "1x";
  return (
    <div className="main-footer-div">
      <div className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h3 className="title">NepaLifeCare</h3>
              <h2 className="text">
                Grote Belt 56, 2133 GB Hoofddorp Netherlands
              </h2>
              <ul className="location">
                <li>
                  {" "}
                  <div className="icon-div">
                    <FontAwesomeIcon icon={faEnvelope} size={iconSize} />{" "}
                    info247@nepalifecare.org
                  </div>
                </li>
                <li>
                  <div className="icon-div">
                    <FontAwesomeIcon icon={faPhone} size={iconSize} />
                    0031 642486868
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-4">
              <h3 className="link-title">Links</h3>
              <ul className="Menu-lists">
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to="/"
                >
                  {" "}
                  <li>Home</li>{" "}
                </Link>
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to="/Comingsoon"
                >
                  {" "}
                  <li>Programs</li>{" "}
                </Link>
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to="/Comingsoon"
                >
                  {" "}
                  <li>About Us</li>{" "}
                </Link>
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to="/ContactPage"
                >
                  {" "}
                  <li>Contact Page</li>{" "}
                </Link>
              </ul>
            </div>

            <div className="col-md-4">
              <h3 className="updated">Stay Updated</h3>
              <input
                className="email-form"
                type="text"
                placeholder="Email Address"
              />
              <br />
              <button className="submit-butan">Submit</button>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="copyright-section text-center">
                © Copyright 2024 | Designed By ANRIKA | All right reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
