import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./Navbar.css";
import logomain from "../../../media/logoo.png";
import "bootstrap/dist/css/bootstrap.min.css";

const MyNavbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false); // State to control Navbar menu collapse
  const location = useLocation();

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavbarToggle = () => {
    setExpanded(!expanded);
  };

  const handleDropdownSelect = () => {
    setExpanded(false);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="main-div-nav">
      <div
        className={`nav-bar-section ${scrolled ? "scrolled" : ""} fixed-top`}
      >
        <Navbar
          expand="lg"
          expanded={expanded}
          onToggle={handleNavbarToggle}
          onSelect={handleDropdownSelect}
        >
          <div className="container">
            <div className="logo-div">
              <img src={logomain} alt="" />
            </div>
            <Navbar.Toggle aria-controls="navbarSupportedContent" />
            <Navbar.Collapse id="navbarSupportedContent">
              <Nav className="ms-auto">
                <Nav.Link href="#" className="nav-link active">
                  <Link
                    className="navlink"
                    style={{
                      color: isActive("/") ? "orange" : "inherit",
                      fontSize: "17px",
                    }}
                    to="/"
                  >
                    Home
                  </Link>
                </Nav.Link>

                <NavDropdown
                  className="navlink"
                  title="Programs"
                  id="basic-nav-dropdown"
                  style={{
                    color: "inherit",
                    fontSize: "17px",
                  }}
                >
                  <NavDropdown.Item href="#">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/Young"
                    >
                      Blood & Life Donation Counselling
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/commingsoon"
                    >
                      Career Counseling & Mentorship
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/commingsoon"
                    >
                      Social Buddies Programs
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/commingsoon"
                    >
                      Social Entrepreneurship & Self-Employment
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/commingsoon"
                    >
                      Innovation & Startups Support
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/commingsoon"
                    >
                      Financial Fit & Wellbeing Programs
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/commingsoon"
                    >
                      Integrated Health
                    </Link>
                  </NavDropdown.Item>
                  {/* Add other dropdown items as needed */}
                </NavDropdown>
                <Nav.Link href="#" className="nav-link">
                  <Link
                    className="navlink"
                    style={{
                      fontSize: "17px",
                      color: isActive("/AboutPage") ? "orange" : "inherit",
                      fontWeight: "500",
                    }}
                    to="/AboutPage"
                  >
                    About Us
                  </Link>
                </Nav.Link>
                <Nav.Link href="#" className="nav-link">
                  <Link
                    className="navlink"
                    style={{
                      fontSize: "17px",
                      color: isActive("/upcomming") ? "orange" : "inherit",
                      fontWeight: "500",
                    }}
                    to="/commingsoon"
                  >
                    Upcoming Events
                  </Link>
                </Nav.Link>
                <Nav.Link href="#" className="nav-link">
                  <Link
                    className="navlink"
                    style={{
                      fontSize: "17px",
                      color: isActive("/Involvedpage") ? "orange" : "inherit",
                      fontWeight: "500",
                    }}
                    to="/commingsoon"
                  >
                    Get Involved
                  </Link>
                </Nav.Link>
                <Nav.Link href="#" className="nav-link">
                  <Link
                    className="navlink"
                    style={{
                      fontSize: "17px",
                      color: isActive("/Contactpage") ? "orange" : "inherit",
                      fontWeight: "500",
                    }}
                    to="/Contactpage"
                  >
                    Contact Us
                  </Link>
                </Nav.Link>
                <Nav.Link href="#" className="nav-link">
                  <button className="nav-button">
                    <Link
                      className="navlink"
                      style={{
                        fontSize: "17px",
                        color: isActive("#") ? "orange" : "inherit",
                      }}
                      to="/commingsoon"
                    >
                      Donate Now
                    </Link>
                  </button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </div>
  );
};

export default MyNavbar;
