import { React, useEffect } from "react";
import Youngheader from "./components/Youngheader";
import Aboutdon from "./components/Aboutdon";
const Young = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, []);
  return (
    <div className="main-young-div">
      <Youngheader />
      <Aboutdon />
    </div>
  );
};

export default Young;
