import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./components/Homepage/Homepage";
import Cgpage from "./components/Cgpage/Cgpage";
import Socialpage from "./components/Socialpage/Socialpage";
import Motivation from "./components/Motivation/Motivation";
import Aboutpage from "./components/Aboutus/Aboutus";
import Involved from "./components/Involved/Involved";
import Contact from "./components/Contact/Contact";
import SocialEnt from "./components/SocialEnt/SocialEnt";
import MyNavbar from "./components/Homepage/components/Navbar";
import Startups from "./components/Startups/Startups";
import Integrated from "./components/Integrated/Integrated";
import Upcomming from "./components/upcomming/upcomming";
import Footer from "./components/Homepage/components/Footer";
import Young from "./components/Young/Young";
import Commingsoon from "./components/commingsoon";
const App = () => {
  return (
    <Router>
      <MyNavbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/commingsoon" element={<Commingsoon />} />
        <Route path="/Young" element={<Young />} />
        <Route path="/cgpage" element={<Cgpage />} />
        <Route path="/Socialpage" element={<Socialpage />} />
        <Route path="/Motivationpage" element={<Motivation />} />
        <Route path="/Aboutpage" element={<Aboutpage />} />
        <Route path="/Involvedpage" element={<Involved />} />
        <Route path="/Contactpage" element={<Contact />} />
        <Route path="/SocialEnt" element={<SocialEnt />} />
        <Route path="/Startups" element={<Startups />} />
        <Route path="/Integrated" element={<Integrated />} />
        <Route path="/upcomming" element={<Upcomming />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
