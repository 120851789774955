// Past.js
import React, { useState } from "react";
import "./Past.css";
import EventModal from "./EventModal";
import saigrace from "../../../media/saigrace.JPG";
import pastimg2 from "../../../media/pastimg2.jpg";
import pastimg3 from "../../../media/pastimg3.jpg";

const Past = () => {
  const [modalData, setModalData] = useState(null);

  const openModal = (eventData) => {
    setModalData(eventData);
  };

  const closeModal = () => {
    setModalData(null);
  };

  const eventsData = [
    {
      title: "World Tour with Saigrace",
      date: "11 Feb, 2024",
      description:
        "Prepare to be enraptured by the enchanting stories of Saigrace as he embarks on his captivating World Tour, making its first stop in the picturesque Netherlands. Beginning on February 11, 2024, in this charming setting, Saigrace will weave a tapestry of tales that will transport you to realms both familiar and fantastical. ",
      image: saigrace,
    },

    // Add more events as needed
  ];

  return (
    <div className="past-main-div">
      <div className="container">
        {eventsData.map((event, index) => (
          <div className="row" key={index}>
            <div className="col-md-4">
              <div className="img-div-past">
                <img src={event.image} alt={`Event ${index + 1}`} />
              </div>
            </div>
            <div className="col-md-7">
              <div className="past-events-contents">
                <span className="event-date">{event.date}</span> <br />
                <a className="event-title">{event.title}</a>
                <p className="event-text">
                  {event.description.slice(0, 200)}...
                </p>
                <a className="read-more" onClick={() => openModal(event)}>
                  Learn More...
                </a>
              </div>
            </div>
            <div className="col-md-1 text-right last-div">
              <div className="shape-event"></div>
            </div>
          </div>
        ))}
      </div>
      {modalData && (
        <EventModal closeModal={closeModal} eventData={modalData} />
      )}
    </div>
  );
};

export default Past;
