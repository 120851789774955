import React from "react";
import Navbar from "../Homepage/components/Navbar";
import Header from "./components/Header";
const SocialEnt = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default SocialEnt;
