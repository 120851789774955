import React from "react";
import "./commingsoon.css";
const commingsoon = () => {
  return (
    <div className="main-div-comming">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="main-textt text-center">
              Stay tuned for exciting updates and announcements as we prepare to
              launch new pages featuring a wide array of charitable activities,
              initiatives, and opportunities for you to get involved. In the
              meantime, feel free to explore the sections that are already
              available on our website.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default commingsoon;
